<template lang="pug">
  .assign-journey__item(@click="$emit('select:journey', journeyItem)")
    v-tooltip(top content-class="tooltip-menu")
      template(v-slot:activator="{ on }")
        span(v-on="on").assign-journey__name {{ journeyItem.internal_name }}
      span.assign-journey__name {{ journeyItem.internal_name }}

    .assign-journey__days
      .journey_day(v-for="(journeyDay, index) in journeyItem.journey_days" :key="journeyDay.id")
        v-tooltip(top content-class="tooltip-menu")
          template(v-slot:activator="{ on }")
            span(v-on="on").day__wrapper
              span.journey__round-day {{ index + 1 }}
              span.journey__course-day {{ cityName(journeyDay.course_day.city) }} ({{ journeyDay.course_day.date | DDMMYYYYFormatting }}) {{ journeyDay.course_day.name }}
          span {{ cityName(journeyDay.course_day.city) }} ({{ journeyDay.course_day.date | DDMMYYYYFormatting}}) {{ journeyDay.course_day.name }}
    .assign-journey__edit
      journey-crud(
        :key="journeyItem.id"
        :journeyItemID="journeyItem.id"
        @click:remove="$emit('needToUpdate')"
        @click:create="$emit('needToUpdate')"
        @click:update="onClickUpdate"
        editable
      )
        template(v-slot:activator="createJourney")
          span(@click="createJourney.open").d-flex.text-label
            ico-dots-horizontal
</template>

<script>
import {DDMMYYYYFormatting} from "@/util";
import {mapGetters} from "vuex"

export default {
  name: 'JourneyAssignItem',

  filters: {
    DDMMYYYYFormatting
  },

  props: {
    journeyItem: Object
  },

  computed: {
    ...mapGetters({
      cities: 'crmCourseCities/items'
    })
  },

  methods: {
    cityName(cityID) {
      let city = this.cities.find(city => city.ID === cityID)
      return city.name
    },

    onClickUpdate(res) {
      this.$emit('needToUpdate', res)
    }
  },

  components: {
    journeyCrud: () => import('@/app/admin/modules/course_day/components/journey/JourneyCRUD.vue'),
    icoDotsHorizontal: () => import('@/assets/img/ui/components/IcoDotsHorizontal.vue')
  }
}
</script>

<style lang="scss">
.tooltip-menu.span {
  color: #FFFFFF;
}

.assign-journey__item {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px;
  margin: 0 0 5px 0;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
  border: solid 0.5px #dfe7eb;
  background-color: #ffffff;
  cursor: pointer;
  transition: all 0.15s ease-in-out;

  &:hover {
    background-color: rgba(#ffffff, 0.1);
  }

  &.active {
    transition: all 0.15s ease-in-out;

    &:before {
      content: ' ';
      width: 3.5px;
      position: absolute;
      background-color: $primary-color;
      height: 100%;
      left: 0;
      right: auto;
      top: 0;
      bottom: auto;
      transition: all 0.15s ease-in-out;
    }
  }
}

.assign-journey {
  &__name {
    font-family: $font-roboto;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    width: 170px;
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__days {
    width: 250px;
    max-width: 250px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: $font-roboto;
    font-size: 11px;
    margin-left: auto;
    overflow: hidden;
    white-space: nowrap;

    .journey_day {
      margin: 2px 0;
      padding-left: 2px;

      .day__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        .journey__round-day {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 17px;
          width: 17px;
          line-height: normal;
          font-size: 10px;
          margin-right: 3px;
          color: $label-color;
          border: 1px solid $label-color;
          border-radius: 50%;
        }

        .journey__course-day {
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  &__edit {
    display: flex;
    align-items: center;
    margin-left: auto;

    i {
      font-size: 16px;
      color: $primary-color;
    }
  }
}
</style>
