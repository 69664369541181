<template lang="pug">
  .assign-candidate-journey
    base-list-selectable(
      :value="selectedCity"
      @input="$emit('update:city', $event)"
      :list="cities"
      slug-key="ID"
    ).assign-candidate-journey__cities
      template(#top)
        slot(name="top")
      template(v-slot:item="{item, selected, select}")
        li(slot="item" :class="{'selected': selected}" @click="select").assign-candidate-journey__city.unselectable
          .assign-candidate-journey__code {{ item.code }}
          span.assign-candidate-journey__city-name {{ item.name }}
    .journey-list__wrapper
      .journey-list__items
        .journey_day__item(v-if="groupedJourneys.length" v-for="groupedJourneyDay in groupedJourneys" :key="groupedJourneyDay.date")
          .journey_day__date {{ groupedJourneyDay.monthDay }}
            .journey_day__date_month {{ groupedJourneyDay.month }}
          .journey_day__wrapper
            journey-assign-item(
              v-for="journeyItem in groupedJourneyDay.items"
              :journeyItem="journeyItem"
              :key="journeyItem.id"
              :class="{'active': isActive(journeyItem)}"
              @needToUpdate="needToUpdate"
              @select:journey="$emit('select:journey', $event)"
            )
        .journey_day__empty(v-if="!groupedJourneys.length")
          p No data available
      intersect(@enter="enter" :treshhold="[0, 0.5]" v-if="!loading && groupedJourneys.length")
          div.intersection-observer
      div(v-if="loading")
        base-preloader.text-primary
</template>

<script>
import BaseListSelectable from '@/components/global/selectedList/BaseListSelectable'
import JourneyAssignItem from '@/app/admin/modules/course_day/components/journey/JourneyAssignItem';
import Intersect from 'vue-intersect'

export default {
  name: 'JourneysList',

  props: {
    cities: Array,
    groupedJourneys: Array,
    loading: Boolean,
    selectedJourney: Object,
    selectedCity: Object
  },

  methods: {
    isActive(item) {
      if (this.selectedJourney && this.selectedJourney.id) return this.selectedJourney.id === item.id
      return false
    },

    enter() {
      this.$emit('enter:end')
    },

    needToUpdate(res) {
      this.$emit('needToUpdate', res)
    }
  },

  components: {
    Intersect,
    BaseListSelectable,
    JourneyAssignItem,
    BasePreloader: () => import('@/components/global/BasePreloader.vue')
  }
}
</script>

<style lang="scss">
.intersection-observer {
  height: 10px;
}

.assign-candidate-journey {
  display: flex;

  &__cities {
    width: 155px;
    border-right: 1px solid $bg-filled;
  }

  &__code {
    display: inline-block;
    margin-right: 8px;
    min-width: 16px;
    min-height: 16px;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background-color: #F4F7F9;
    color: #000000;
    vertical-align: middle;
    text-align: center;
    font-weight: 900;
    font-size: 7px;
    line-height: 16px;
  }

  &__city {
    position: relative;
    display: flex;
    align-items: center;
    padding: 4.5px 12px;
    font-weight: 500;
    font-size: 12px;
    cursor: pointer;

    &-name {
      display: inline-block;
      vertical-align: middle;
    }

    &:hover {
      color: $primary-color;
    }

    &.selected {
      background-color: $primary-color;
      color: #FFFFFF;

      &::after,
      &::before {
        position: absolute;
        right: 0;
        left: 0;
        height: 4px;
        background-color: $primary-color;
        content: "";
      }

      &::after {
        top: -4px;
      }

      &::before {
        bottom: -4px;
      }

      &:first-child::after {
        display: none;
      }

      &:last-child::before {
        display: none;
      }
    }
  }
}

.journey-list__wrapper {
  width: 100%;
  background: #F4F7F9;

  .journey-list__items {
    .journey_day__item {
      position: relative;
      display: flex;
      padding-top: 20px;
      padding-right: 6px;
      padding-left: 6px;

      &:first-child {
        padding-top: 10px;
      }

      &:first-child:before {
        display: none;
      }

      &::before {
        position: absolute;
        top: 0;
        left: 24px;
        margin: 0 auto;
        width: 1px;
        height: 20px;
        background-color: #DFE7EB;
        content: "";
      }

      &::after {
        position: absolute;
        top: 57px;
        bottom: 0;
        left: 24px;
        margin: 0 auto;
        width: 1px;
        height: 100%;
        background-color: #DFE7EB;
        content: "";
      }

      &:last-child::after {
        display: none;
      }
    }

    .journey_day__date {
      position: relative;
      margin-right: 6px;
      padding: 6px;
      width: 37px;
      height: 37px;
      border-radius: 4px;
      background: #FFFFFF;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
      color: $title-second;
      text-align: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 1;
      &_month {
        margin-top: 2px;
        color: $label-color;
        font-weight: bold;
        font-size: 8px;
      }

    }

    .journey_day__wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-right: 5px;
    }

    .journey_day__empty {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;
      padding: 20px;

      p {
        font-family: $font-roboto;
        font-size: 13px;
        color: $label-color;
        line-height: normal;
      }
    }
  }
}
</style>
